
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import ReportPieChart from "@/components/report-pie-chart/Main.vue";
import VerticalBarChart from "@/components/vertical-bar-chart/Main.vue";
import dayjs from "dayjs";
import { orderService } from "@/services/order";
import { showError } from "@/libs/notification";
import { OrderStatusDisplay, OrderColorDisplay } from "@/configs/common";

export default defineComponent({
  components: {
    ReportPieChart,
    VerticalBarChart
  },
  data() {
    return {
      OrderStatusDisplay,
      OrderColorDisplay
    };
  },
  setup() {
    const picked = ref("");
    const orderSummary = ref({});
    const previousOrderSummary = ref({});
    const codStatusCount = ref<any>({});
    const codStatusCountDetail = ref<any>([]);
    const countByProvince = ref<any>([]);

    const getQueryHome = (params: any, previousParams: any) => {
      orderService
        .getHomeQuery(params)
        .then((res: any) => {
          orderSummary.value = res.result.order_summary;
          codStatusCount.value = res.result.cod_status_count;
        })
        .catch((err: any) => {
          showError(err);
        });
      orderService
        .getHomeQuery(previousParams)
        .then((res: any) => {
          previousOrderSummary.value = res.result.order_summary;
        })
        .catch((err: any) => {
          showError(err);
        });
    };

    const getHomeQueryDetail = (params: any) => {
      orderService
        .getHomeQueryDetail(params)
        .then((res: any) => {
          codStatusCountDetail.value = res.result;
        })
        .catch((err: any) => {
          showError(err);
        });
    };

    const getCountByProvince = (params: any) => {
      orderService
        .getHomeQueryProvince(params)
        .then((res: any) => {
          countByProvince.value = res.result;
        })
        .catch((err: any) => {
          showError(err);
        });
    };

    const selectRange = (value: string) => {
      picked.value = value;
    };

    watch(picked, (newValue) => {
      let startDate = dayjs();
      let previousDate = dayjs();
      switch (newValue) {
        case "1D":
          startDate = dayjs();
          previousDate = dayjs().subtract(1, "day");
          break;
        case "7D":
          startDate = dayjs().subtract(7, "day");
          previousDate = dayjs().subtract(14, "day");
          break;
        case "1M":
          startDate = dayjs().subtract(30, "day");
          previousDate = dayjs().subtract(60, "day");
          break;
      }
      const params = {
        startDate: startDate.format("YYYY/MM/DD"),
        endDate: dayjs().format("YYYY/MM/DD") + " 23:59:59",
        statusNotIn: "PENDING,CANCEL_ORDER"
      };
      const previousParams = {
        startDate: previousDate.format("YYYY/MM/DD"),
        endDate: startDate.subtract(1, "day").format("YYYY/MM/DD") + " 23:59:59",
        statusNotIn: "PENDING,CANCEL_ORDER"
      };
      getQueryHome(params, previousParams);
      getHomeQueryDetail(params);
      getCountByProvince(params);
    });

    const chartData = computed(() => {
      return [codStatusCount.value.SIGN, codStatusCount.value.NOT_SIGN, codStatusCount.value.RETURN];
    });

    const total = computed(
      () => codStatusCount.value.SIGN + codStatusCount.value.NOT_SIGN + codStatusCount.value.RETURN
    );

    const provinceChartLabels = computed(() => {
      // eslint-disable-next-line camelcase
      return countByProvince.value.slice(0, 10).map((item: { receiver_province: any }) => item.receiver_province);
    });

    const provinceChartData = computed(() => {
      // eslint-disable-next-line camelcase
      return countByProvince.value.slice(0, 10).map((item: { total: any }) => item.total);
    });

    onMounted(() => {
      picked.value = "7D";
    });

    return {
      picked,
      orderSummary,
      previousOrderSummary,
      codStatusCount,
      codStatusCountDetail,
      countByProvince,
      chartData,
      provinceChartLabels,
      provinceChartData,
      total,
      selectRange
    };
  }
});
