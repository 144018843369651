<template>
  <Chart type="bar" :width="width" :height="height" :data="data" :options="options" />
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    labels: {
      type: Array,
    },
    chartData: {
      type: Array,
    },
  },
  setup(props) {
    const data = computed(() => {
      return {
        labels: props.labels,
        datasets: [
          {
            label: "Tong so don",
            barPercentage: 0.5,
            barThickness: 16,
            maxBarThickness: 18,
            minBarLength: 2,
            data: props.chartData,
            backgroundColor: "#3160D8",
          },
        ],
      };
    });
    const options = computed(() => {
      return {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      };
    });

    return {
      data,
      options,
    };
  },
});
</script>
