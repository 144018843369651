export type Pagination = {
  page?: number;
  pageSize?: number;
  total?: number;
};

export const OrderStatusDetailDisplay: { [key: string]: string } = {
  PUSAT_DISPATCH: "HQ điều phối cho bưu cục",
  CANCEL_ORDER: "Hủy đơn đặt",
  Send: "Đang gửi đến bưu cục phát",
  WARE_HOUSING: "Nhập kho",
  WareHousing: "Nhập kho",
  PICKUP_FAILED: "Lấy kiện thất bại",
  PENDING: "Đợi nhận kiện",
  ABNORMAL_PARCEL: "Kiện vấn đề",
  "abnormal parcel": "Kiện vấn đề",
  DeliveryFail: "Giao hàng thất bại",
  SIGN: "Quét mã ký nhận",
  SIGN_COLLECTED: "Quét mã ký nhận",
  REGISTER_RETURN: "Đăng ký chuyển hoàn",
  CONFIRM_RETURN: "Xác nhận chuyển hoàn",
  DELIVERING: "Đang phát kiện",
  RETURNED_SIGN: "Chuyển hoàn thành công",
  "PICKUP FAILED": "Lấy kiện thất bại",
  Arrive: "Kiện đến bưu cục",
  GOT: "Quét mã nhận kiện",
  Return: "Đăng ký chuyển hoàn",
  DISPATCH: "Bưu cục điều phối cho nhân viên"
};

export const OrderStatusDisplay: { [key: string]: string } = {
  SIGN: "Ký nhận",
  RETURNED_SIGN: "Chuyển hoàn",
  GOT: "Đã nhận kiện",
  DELIVERING: "Đang phát kiện",
  ABNORMAL_PARCEL: "Kiện vấn đề",
  TOTAL: "Tổng đơn hàng",
};

export const OrderColorDisplay: { [key: string]: number| string } = {
  SIGN: 9,
  RETURNED_SIGN: 12,
  ABNORMAL_PARCEL: 1,
  GOT: '#2449AF',
  DELIVERING: '#3CAEA3',
  TOTAL: '#621940',
};

