import Toastify from "toastify-js";

export const showError = (text: string) => {
  showNotification(text, "center", "top", "#D32929");
};

export const showSuccess = (text: string) => {
  showNotification(text, "center", "top", "#91C714");
};

export const showWarning = (text: string) => {
  showNotification(text, "center", "top", "orange");
};

export const getWaring = (text: string): any => {
  return getToastify(text, "center", "top", "orange", 0);
};

export const getToastify = (
  text: string,
  position = "center",
  gravity = "top",
  color = "#0e2c8",
  duration = 3000
): any => {
  return Toastify({
    text: text,
    duration: duration,
    newWindow: true,
    close: true,
    gravity: gravity,
    position: position,
    backgroundColor: color,
    stopOnFocus: true,
  });
};

export const showNotification = (
  text: string,
  position = "center",
  gravity = "top",
  color = "#0e2c8",
  duration = 3000
) => {
  getToastify(text, position, gravity, color, duration).showToast();
};
