import { client } from "./client";
import { OrderPayload } from "@/configs/orders";
import { PrintType } from "@/configs/jtTypes";

export const orderService = {
  getOrders(params: OrderPayload): any {
    return client.get("/shop/orders", { params }).then((res) => res.data);
  },
  getStatusCount(params: OrderPayload): any {
    return client.get("/shop/order-status-count", { params }).then((res) => res.data);
  },
  getTracking(billCode: string): any {
    return client.get(`/tracking?billCode=${billCode}`).then((res) => res.data);
  },
  getTrackingMultiple(billCodes: string): any {
    return client.get(`/tracking-multiple?billCodes=${billCodes}`).then((res) => res.data);
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createOrders(params: any): any {
    return client.post("/shop/orders", params).then((res) => res.data);
  },
  print(ids: string[], template: PrintType, sortField: string): any {
    return client.post("/shop/orders/print", { ids, template, sortField }).then((res) => res.data);
  },
  cancelOrder(ids: string[]): any {
    return client.post("/shop/orders/cancel", { ids }).then((res) => res.data);
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getHomeQuery(params: any): any {
    return client.get("/shop/home-query", { params }).then((res) => res.data);
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getHomeQueryDetail(params: any): any {
    return client.get("/shop/home-query-detail", { params }).then((res) => res.data);
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getHomeQueryProvince(params: any): any {
    return client.get("/shop/home-query-province", { params }).then((res) => res.data);
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getStatusSummary(params: any): any {
    return client.get("/shop/order-status-summary", { params }).then((res) => res.data);
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getSummary(params: any): any {
    return client.get("/shop/order-summary", { params }).then((res) => res.data);
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getCodPrepaids(params: any): any {
    return client.get("/shop/codPrepaid", { params }).then((res) => res.data);
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getCodPrepaidsDetail(params: any): any {
    return client.get("/shop/codPrepaidDetail", { params }).then((res) => res.data);
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getCodTraffic(params: any): any {
    return client.get("/shop/codTraffic", { params }).then((res) => res.data);
  },
};
