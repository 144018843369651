<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
      <!-- BEGIN: General Report -->
      <div class="col-span-12">
        <div class="intro-y flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">Báo cáo chung</h2>
          <div class="mt-3 ml-auto">
            <div class="flex flex-col sm:flex-row mt-2">
              <button
                class="button bg-white text-black mr-1"
                :class="{ 'bg-theme-1 text-white': picked === '1D' }"
                @click="selectRange('1D')"
              >
                1D
              </button>
              <button
                class="button bg-white text-black mr-1"
                :class="{ 'bg-theme-1 text-white': picked === '7D' }"
                @click="selectRange('7D')"
              >
                7D
              </button>
              <button
                class="button bg-white text-black mr-1"
                :class="{ 'bg-theme-1 text-white': picked === '1M' }"
                @click="selectRange('1M')"
              >
                1M
              </button>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12 gap-6 mt-5">
          <div class="col-span-12 sm:col-span-4 xl:col-span-4 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <ShoppingCartIcon class="report-box__icon text-theme-10" />
                  <div class="ml-auto" v-if="previousOrderSummary.orders > 0 && orderSummary.orders > 0">
                    <Tippy
                      tag="div"
                      class="report-box__indicator bg-theme-9 cursor-pointer"
                      content="phần trăm cao hơn cùng kỳ"
                      v-if="orderSummary.orders >= previousOrderSummary.orders"
                    >
                      {{
                        (
                          ((orderSummary.orders - previousOrderSummary.orders) / previousOrderSummary.orders) *
                          100
                        ).toFixed(2)
                      }}%
                      <ChevronUpIcon class="w-4 h-4" />
                    </Tippy>
                    <Tippy
                      tag="div"
                      class="report-box__indicator bg-theme-6 cursor-pointer"
                      content="phần trăm thấp hơn cùng kỳ"
                      v-else
                    >
                      {{
                        (((previousOrderSummary.orders - orderSummary.orders) / orderSummary.orders) * 100).toFixed(2)
                      }}%
                      <ChevronDownIcon class="w-4 h-4" />
                    </Tippy>
                  </div>
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">
                  {{ $h.formatCurrency(orderSummary.orders ?? 0) }}
                </div>
                <div class="text-base text-gray-600 mt-1">Số đơn</div>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-4 xl:col-span-4 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <TruckIcon class="report-box__icon text-theme-11" />
                  <div class="ml-auto" v-if="previousOrderSummary.freight > 0 && orderSummary.freight > 0">
                    <Tippy
                      tag="div"
                      class="report-box__indicator bg-theme-9 cursor-pointer"
                      content="phần trăm cao hơn cùng kỳ"
                      v-if="orderSummary.freight >= previousOrderSummary.freight"
                    >
                      {{
                        (
                          ((orderSummary.freight - previousOrderSummary.freight) / previousOrderSummary.freight) *
                          100
                        ).toFixed(2)
                      }}%
                      <ChevronUpIcon class="w-4 h-4" />
                    </Tippy>
                    <Tippy
                      tag="div"
                      class="report-box__indicator bg-theme-6 cursor-pointer"
                      content="phần trăm thấp hơn cùng kỳ"
                      v-else
                    >
                      {{
                        (((previousOrderSummary.freight - orderSummary.freight) / orderSummary.freight) * 100).toFixed(
                          2
                        )
                      }}%
                      <ChevronDownIcon class="w-4 h-4" />
                    </Tippy>
                  </div>
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">
                  {{ $h.formatCurrency(orderSummary.freight ?? 0) }}
                </div>
                <div class="text-base text-gray-600 mt-1">Vận phí</div>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-4 xl:col-span-4 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <DollarSignIcon class="report-box__icon text-theme-12" />
                  <div class="ml-auto" v-if="previousOrderSummary.cod > 0 && orderSummary.cod > 0">
                    <Tippy
                      tag="div"
                      class="report-box__indicator bg-theme-9 cursor-pointer"
                      content="phần trăm cao hơn cùng kỳ"
                      v-if="orderSummary.cod >= previousOrderSummary.cod"
                    >
                      {{
                        (((orderSummary.cod - previousOrderSummary.cod) / previousOrderSummary.cod) * 100).toFixed(2)
                      }}%
                      <ChevronUpIcon class="w-4 h-4" />
                    </Tippy>
                    <Tippy
                      tag="div"
                      class="report-box__indicator bg-theme-6 cursor-pointer"
                      content="phần trăm thấp hơn cùng kỳ"
                      v-else
                    >
                      {{ (((previousOrderSummary.cod - orderSummary.cod) / orderSummary.cod) * 100).toFixed(2) }}%
                      <ChevronDownIcon class="w-4 h-4" />
                    </Tippy>
                  </div>
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">
                  {{ $h.formatCurrency(orderSummary.cod ?? 0) }}
                </div>
                <div class="text-base text-gray-600 mt-1">Tiền Cod</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: General Report -->
      <!-- BEGIN: Cod status chart -->
      <div class="col-span-12">
        <div class="intro-y flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">Trạng thái</h2>
        </div>
        <div class="grid grid-cols-12 gap-6 mt-5">
          <div class="intro-y box p-5 mt-5 col-span-12 sm:col-span-4 xl:col-span-3">
            <ReportPieChart
              :height="300"
              class="mt-3"
              :labels="['Ký nhận', 'Chưa ký nhận', 'Chuyển hoàn']"
              :chart-data="chartData"
            />
            <div>
              <div class="flex items-center">
                <span class="truncate"><strong>Tổng</strong></span>
                <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                <span class="font-medium xl:ml-auto">
                  {{ total }}
                  (100%)
                </span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-9 rounded-full mr-3"></div>
                <span class="truncate">Ký nhận</span>
                <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                <span class="font-medium xl:ml-auto">
                  {{ codStatusCount.SIGN }}
                  ({{ total > 0 ? ((codStatusCount.SIGN / total) * 100).toFixed(2) : 0 }}%)
                </span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>
                <span class="truncate">Chưa ký nhận</span>
                <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                <span class="font-medium xl:ml-auto">
                  {{ codStatusCount.NOT_SIGN }}
                  ({{ total > 0 ? ((codStatusCount.NOT_SIGN / total) * 100).toFixed(2) : 0 }}%)
                </span>
              </div>
              <div class="flex items-center mt-4">
                <div class="w-2 h-2 bg-theme-12 rounded-full mr-3"></div>
                <span class="truncate">Chuyển hoàn</span>
                <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                <span class="font-medium xl:ml-auto">
                  {{ codStatusCount.RETURN }}
                  ({{ total > 0 ? ((codStatusCount.RETURN / total) * 100).toFixed(2) : 0 }}%)
                </span>
              </div>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5 col-span-12 sm:col-span-4 xl:col-span-3">
            <div class="accordion">
              <div
                class="accordion__pane border-b border-gray-200 dark:border-dark-5 pt-4 pb-4"
                :class="{ active: index === 'TOTAL' }"
                v-for="(status, index) in codStatusCountDetail"
                :key="index"
              >
                <div class="flex items-center accordion__pane__toggle font-medium block cursor-pointer">
                  <div v-if="typeof(OrderColorDisplay[index]) === 'number'"
                       :class="`w-3 h-3 bg-theme-${OrderColorDisplay[index]} mr-3`"></div>
                  <div v-else class="w-3 h-3 mr-3" :style="`background-color: ${OrderColorDisplay[index]}`"></div>
                  <span class="truncate">{{ OrderStatusDisplay[index] }}</span>
                  <span class="font-medium xl:ml-auto"> {{ $h.formatCurrency(status.orders) }}</span>
                  <ChevronsDownIcon class="ml-2 w-4 h-4" />
                </div>
                <div class="accordion__pane__content mt-3 text-gray-700 dark:text-gray-600">
                  <div class="flex items-center mt-4">
                    <span class="truncate">Vận phí</span>
                    <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                    <span class="font-medium xl:ml-auto"> {{ $h.formatCurrency(status.cod) }} đ </span>
                  </div>
                  <div class="flex items-center mt-4">
                    <span class="truncate">Cod</span>
                    <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                    <span class="font-medium xl:ml-auto"> {{ $h.formatCurrency(status.freight) }} đ </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5 col-span-12 sm:col-span-4 xl:col-span-6">
            <VerticalBarChart
              :height="150"
              class="mt-3"
              :labels="provinceChartLabels"
              :chart-data="provinceChartData"
            />
            <div class="text-center w-full mt-3">
              <strong class="text-2xl">Top 10 tỉnh thành nhận</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import ReportPieChart from "@/components/report-pie-chart/Main.vue";
import VerticalBarChart from "@/components/vertical-bar-chart/Main.vue";
import dayjs from "dayjs";
import { orderService } from "@/services/order";
import { showError } from "@/libs/notification";
import { OrderStatusDisplay, OrderColorDisplay } from "@/configs/common";

export default defineComponent({
  components: {
    ReportPieChart,
    VerticalBarChart
  },
  data() {
    return {
      OrderStatusDisplay,
      OrderColorDisplay
    };
  },
  setup() {
    const picked = ref("");
    const orderSummary = ref({});
    const previousOrderSummary = ref({});
    const codStatusCount = ref<any>({});
    const codStatusCountDetail = ref<any>([]);
    const countByProvince = ref<any>([]);

    const getQueryHome = (params: any, previousParams: any) => {
      orderService
        .getHomeQuery(params)
        .then((res: any) => {
          orderSummary.value = res.result.order_summary;
          codStatusCount.value = res.result.cod_status_count;
        })
        .catch((err: any) => {
          showError(err);
        });
      orderService
        .getHomeQuery(previousParams)
        .then((res: any) => {
          previousOrderSummary.value = res.result.order_summary;
        })
        .catch((err: any) => {
          showError(err);
        });
    };

    const getHomeQueryDetail = (params: any) => {
      orderService
        .getHomeQueryDetail(params)
        .then((res: any) => {
          codStatusCountDetail.value = res.result;
        })
        .catch((err: any) => {
          showError(err);
        });
    };

    const getCountByProvince = (params: any) => {
      orderService
        .getHomeQueryProvince(params)
        .then((res: any) => {
          countByProvince.value = res.result;
        })
        .catch((err: any) => {
          showError(err);
        });
    };

    const selectRange = (value: string) => {
      picked.value = value;
    };

    watch(picked, (newValue) => {
      let startDate = dayjs();
      let previousDate = dayjs();
      switch (newValue) {
        case "1D":
          startDate = dayjs();
          previousDate = dayjs().subtract(1, "day");
          break;
        case "7D":
          startDate = dayjs().subtract(7, "day");
          previousDate = dayjs().subtract(14, "day");
          break;
        case "1M":
          startDate = dayjs().subtract(30, "day");
          previousDate = dayjs().subtract(60, "day");
          break;
      }
      const params = {
        startDate: startDate.format("YYYY/MM/DD"),
        endDate: dayjs().format("YYYY/MM/DD") + " 23:59:59",
        statusNotIn: "PENDING,CANCEL_ORDER"
      };
      const previousParams = {
        startDate: previousDate.format("YYYY/MM/DD"),
        endDate: startDate.subtract(1, "day").format("YYYY/MM/DD") + " 23:59:59",
        statusNotIn: "PENDING,CANCEL_ORDER"
      };
      getQueryHome(params, previousParams);
      getHomeQueryDetail(params);
      getCountByProvince(params);
    });

    const chartData = computed(() => {
      return [codStatusCount.value.SIGN, codStatusCount.value.NOT_SIGN, codStatusCount.value.RETURN];
    });

    const total = computed(
      () => codStatusCount.value.SIGN + codStatusCount.value.NOT_SIGN + codStatusCount.value.RETURN
    );

    const provinceChartLabels = computed(() => {
      // eslint-disable-next-line camelcase
      return countByProvince.value.slice(0, 10).map((item: { receiver_province: any }) => item.receiver_province);
    });

    const provinceChartData = computed(() => {
      // eslint-disable-next-line camelcase
      return countByProvince.value.slice(0, 10).map((item: { total: any }) => item.total);
    });

    onMounted(() => {
      picked.value = "7D";
    });

    return {
      picked,
      orderSummary,
      previousOrderSummary,
      codStatusCount,
      codStatusCountDetail,
      countByProvince,
      chartData,
      provinceChartLabels,
      provinceChartData,
      total,
      selectRange
    };
  }
});
</script>
